import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import classnames from "classnames"
import logo from './../../../../assets/img/logo/logo-white.png'

class SidebarHeader extends Component {
  render() {
    let {
      menuShadow
    } = this.props
    return (
      <div className="navbar-header" style={{ height: '100px' }}>
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item mr-auto" style={{ marginLeft: 'auto' }}>
            <NavLink to="/home" className="navbar-brand">
              <img src={logo} alt={logo} style={{ width: '100px' }} />
            </NavLink>
          </li>
        </ul>
        <div
          className={classnames("shadow-bottom", {
            "d-none": menuShadow === false
          })}
        />
      </div>
    )
  }
}

export default SidebarHeader
